// @ts-nocheck
import MainContainer from "../../layout/MainContainer";
import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Input,
  TextField,
  Typography,
} from "@mui/material";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ChangeEvent, useEffect, useState } from "react";

import CancelIcon from "@mui/icons-material/Cancel";
import AddIcon from "@mui/icons-material/Add";
import moment from "moment";
import { Loader, showError, showToast } from "../../constants";
import { UploadMedia, UploadVideo } from "../../utils/mediaUpload";
import { isString } from "../../utils/validations";
import {
  useLazyGetTutorialByIdQuery,
  usePostAddTutorialMutation,
} from "../../services/tutorial";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import { CommonBody } from "../../types/General";
import useTranslation from "../../hooks/Translation";

const AddTutorial = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;
  const [addTutorial] = usePostAddTutorialMutation();
  const [tutorialById] = useLazyGetTutorialByIdQuery();

  const [loading, setLoading] = useState(false);
  const [thumbnail, setThumbnail] = useState<string>("");
  const [tutorialData, setTutorialData] = useState<any>();
  const [value, setValue] = useState("sell");

  const [error, setError] = useState(false);
  const [video, setVideo] = useState<string | null>(null);
  const [image, setImage] = useState<string | undefined>("");
  const translate = useTranslation();
  console.log(tutorialData, "tutorialData");

  // const convertDataUrltoImgUrl = async (base64Image: any) => {
  //   const byteCharacters = atob(base64Image.split(",")[1]);
  //   const byteNumbers = new Array(byteCharacters.length);
  //   for (let i = 0; i < byteCharacters.length; i++) {
  //     byteNumbers[i] = byteCharacters.charCodeAt(i);
  //   }
  //   const byteArray = new Uint8Array(byteNumbers);
  //   const blob = new Blob([byteArray], { type: "image/jpeg" });

  //   // Create an image URL from the Blob
  //   const imageUrl = URL.createObjectURL(blob);
  //   // Now, 'imageUrl' contains a regular image URL that you can use in your application
  //   const response = await UploadMedia(blob);
  //   if (response?.statusCode === 200) {
  //     // formik.setFieldValue("coverImg", response?.data);
  //     setThumbnail(response?.data);
  //   } else {
  //     showError(response?.message);
  //   }
  // };

  // const handleVideoUpload = async (
  //   event: React.ChangeEvent<HTMLInputElement>
  // ) => {
  //   const file = event.target.files?.[0];

  //   if (file) {
  //     setLoading(true);
  //     // Create a video element
  //     const video = document.createElement("video");
  //     video.className = "upVideo";
  //     video.preload = "metadata";
  //     // Set the video source
  //     video.src = URL.createObjectURL(file);

  //     // Listen for the loadedmetadata event
  //     video.addEventListener("loadeddata", async () => {
  //       const duration = video.duration;
  //       console.log(duration, "sdfdsfdfsdf");

  //       // setVideoDuration(Math.ceil(duration));
  //       // Set the currentTime to a suitable position for capturing a thumbnail
  //       video.currentTime = Math.min(1, duration); // Capture thumbnail after 1 second

  //       // Wait for the seeked event
  //       video.addEventListener("seeked", async () => {
  //         // Create a canvas element
  //         const canvas = document.createElement("canvas");
  //         canvas.width = video.videoWidth;
  //         canvas.height = video.videoHeight;

  //         // Draw the current frame onto the canvas
  //         const context = canvas.getContext("2d");
  //         context?.drawImage(video, 0, 0, canvas.width, canvas.height);

  //         // Convert the canvas to a data URL representing the thumbnail
  //         const base64Image = canvas.toDataURL("image/jpeg");
  //         convertDataUrltoImgUrl(base64Image);

  //         if (file.type.startsWith("video/")) {
  //           const res = await UploadVideo(file);
  //           if (res?.statusCode === 200) {
  //             formik.setFieldValue("videoLink", res?.data);
  //             setLoading(false);
  //           } else {
  //             setLoading(false);
  //             showError("Invalid file format : only .mp4 files are allowed");
  //           }
  //         } else {
  //           // Display an error message for non-image files
  //           showError("Please select a valid video");
  //         }
  //         // Clean up: remove the video and canvas elements
  //         video.remove();
  //         canvas.remove();
  //       });
  //     });

  //     // Append the video element to the document (necessary for triggering events)
  //     document.body.appendChild(video);
  //   }
  // };

  const handleVideoUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];

    if (file) {
      setLoading(true);

      // Read the file as a data URL using FileReader
      const reader = new FileReader();

      reader.onloadend = async () => {
        const dataUrl = reader.result as string;

        // Convert data URL to Blob
        const byteCharacters = atob(dataUrl.split(",")[1]);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: file.type });

        // Capture thumbnail
        const thumbnailDataUrl = await captureVideoThumbnail(blob);

        // Convert thumbnail data URL to Blob
        const thumbnailByteCharacters = atob(thumbnailDataUrl.split(",")[1]);
        const thumbnailByteNumbers = new Array(thumbnailByteCharacters.length);
        for (let i = 0; i < thumbnailByteCharacters.length; i++) {
          thumbnailByteNumbers[i] = thumbnailByteCharacters.charCodeAt(i);
        }
        const thumbnailByteArray = new Uint8Array(thumbnailByteNumbers);
        const thumbnailBlob = new Blob([thumbnailByteArray], {
          type: "image/jpeg",
        });

        // Upload the video
        const videoUploadResponse = await UploadVideo(blob);
        const thumbnailUploadResponse = await UploadMedia(thumbnailBlob);

        if (
          videoUploadResponse?.statusCode === 200 &&
          thumbnailUploadResponse?.statusCode === 200
        ) {
          formik.setFieldValue("videoLink", videoUploadResponse?.data);
          setThumbnail(thumbnailUploadResponse?.data);
          setLoading(false);
        } else {
          setLoading(false);
          showError("Invalid file format: only .mp4 files are allowed");
        }
      };

      // Read the file as a data URL
      reader.readAsDataURL(file);
    } else {
      showError("Please select a valid video");
    }
  };

  const captureVideoThumbnail = async (videoBlob: Blob): Promise<string> => {
    return new Promise((resolve) => {
      const video = document.createElement("video");
      video.preload = "metadata";
      video.src = URL.createObjectURL(videoBlob);

      video.addEventListener("loadeddata", () => {
        const canvas = document.createElement("canvas");
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;

        const context = canvas.getContext("2d");
        context?.drawImage(video, 0, 0, canvas.width, canvas.height);

        const thumbnailDataUrl = canvas.toDataURL("image/jpeg");

        // Clean up: remove the video and canvas elements
        video.remove();
        canvas.remove();

        resolve(thumbnailDataUrl);
      });
    });
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      title: tutorialData?.title || "",
      title_ar: tutorialData?.title_ar || "",
      description: tutorialData?.description || "",
      description_ar: tutorialData?.description_ar || "",
      videoLink: tutorialData?.video || "",
    },
    validationSchema: Yup.object({
      title: Yup.string()
        .required("This field is required")
        .max(80, "Maximum 80 character are allowed")
        .min(3, "Minimum 2 characters are required"),
      description: Yup.string()
        .required("This field is required")
        .max(80, "Maximum 80 character are allowed")
        .min(3, "Minimum 2 characters are required"),
      title_ar: Yup.string()
        .required("This field is required")
        .max(80, "Maximum 80 character are allowed")
        .min(3, "Minimum 2 characters are required"),
      description_ar: Yup.string()
        .required("This field is required")
        .max(80, "Maximum 80 character are allowed")
        .min(3, "Minimum 2 characters are required"),
      videoLink: Yup.string().required("This field is required"),
    }),
    onSubmit: async (values) => {
      if (!values?.videoLink) {
        setError(true);
      }

      formik.setSubmitting(true);

      const data = {
        title: formik.values.title,
        description: formik.values.description,
        title_ar: formik.values.title_ar,
        description_ar: formik.values.description_ar,
        video: values?.videoLink,
        thumbnail: thumbnail,
      };
      console.log(data, "body for adding");


      if (id && id !== "add") {
        try {
          setLoading(true);
          let encryptedBody = generateEncryptedKeyBody(data) as CommonBody;
          const response = await addTutorial(encryptedBody).unwrap();
          if (response?.statusCode === 200) {
            setLoading(false);
            showToast("Tutorial Updated Successfully");
            navigate("/manage-tutorials");
          } else {
            setLoading(false);
            showError(response?.message || "");
          }
        } catch (error: any) {
          setLoading(false);
          showError(error?.data?.message || "");
          console.log(error);
        }
      } else {
        try {
          setLoading(true);
          let encryptedBody = generateEncryptedKeyBody(data) as CommonBody;
          const response = await addTutorial(encryptedBody).unwrap();
          if (response?.statusCode === 200) {
            setLoading(false);
            showToast("Tutorial Added Successfully");
            formik.resetForm();
            navigate("/manage-tutorials");
          } else {
            setLoading(false);
            showError(response?.message || "");
          }
        } catch (error: any) {
          setLoading(false);
          showError(error?.data?.message || "");
          console.log(error);
        }
      }
    },
  });

  const getTutorialDetail = async (id: string) => {
    try {
      const response = await tutorialById({ subId: id }).unwrap();
      if (response?.statusCode === 200) {
        setTutorialData(response?.data);
        setThumbnail(response?.data?.thumbnail);
      }
    } catch (error: any) {
      console.log(error, "errror");
      showError(error?.data?.message || "");
    }
  };

  useEffect(() => {
    if (id && id !== "add") {
      getTutorialDetail(id);
    }
  }, []);

  return (
    <div>
      <>
        <Loader isLoad={loading} />
        <MainContainer>
          <div className="main_loyout">
            <div className="dashboard">
              {state?.type === "edit" ? (
                <h1 className="mn_hdng">{translate?.main?.EditTutorial}</h1>
              ) : (
                <h1 className="mn_hdng">{translate?.main?.AddTutorial}</h1>
              )}
              <Button
                className="btn btn_primary"
                onClick={() => {
                  navigate("/manage-tutorials");
                }}
              >
                {translate?.main?.Back}
              </Button>
            </div>
            <Card className="cards">
              <form onSubmit={formik.handleSubmit}>
                <CardContent sx={{ p: 1 }}>
                  <Grid container spacing={2}>
                    <Grid item lg={6} md={4} sm={4} xs={12}>
                      <Typography className="custom_label">
                        {translate?.main?.UploadVideos}
                      </Typography>
                      {formik?.values?.videoLink ? (
                        <div className="upload_image_preview2">
                          <video controls width="100%" height="100%">
                            <source
                              src={formik?.values?.videoLink}
                              type="video/mp4"
                            />
                          </video>
                          <CancelIcon
                            onClick={() =>
                              formik.setFieldValue("videoLink", "")
                            }
                          />
                        </div>
                      ) : (
                        <Box className="upload_image_bnr">
                          <label htmlFor="icon-button-file-vdo">
                            <Input
                              sx={{ display: "none" }}
                              id="icon-button-file-vdo"
                              type="file"
                              inputProps={{
                                accept: "video/*",
                              }}
                              onChange={handleVideoUpload}
                            />
                            <Button
                              component="span"
                              className="upload_image_btn"
                            >
                              <AddIcon />
                            </Button>
                          </label>
                        </Box>
                      )}
                      {error && !formik.values.videoLink ? (
                        <h6 className="err_msg">This field is required</h6>
                      ) : (
                        ""
                      )}
                    </Grid>
                    <Grid item lg={6} md={4} sm={4} xs={12} />
                    <Grid item lg={6} md={4} sm={4} xs={12}>
                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Typography className="custom_label"> {translate?.main?.Title} {translate?.main?.English}</Typography>
                        <TextField
                          hiddenLabel
                          type="text"
                          name="title"
                          variant="outlined"
                          fullWidth
                          placeholder="Title (English)"
                          id="title"
                          className="text_field"
                          onChange={(val) => {
                            if (
                              val.target.value === " " ||
                              val.target.value === "."
                            ) {
                            } else if (isString(val.target.value)) {
                              formik.handleChange(val);
                            }
                          }}
                          onBlur={formik.handleBlur}
                          value={formik.values.title}
                          helperText={
                            formik.touched.title && formik.errors.title
                          }
                        />
                      </Grid>
                      <div style={{ marginTop: 20 }} />

                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Typography className="custom_label">
                          {translate?.main?.Description} {translate?.main?.English}
                        </Typography>
                        <TextField
                          className="text_field"
                          hiddenLabel
                          type="text"
                          placeholder="Description (English)"
                          fullWidth
                          multiline
                          minRows={4}
                          maxRows={4}
                          name="description"
                          variant="outlined"
                          id="description"
                          onChange={(val) => {
                            if (
                              val.target.value === " " ||
                              val.target.value === "."
                            ) {
                            } else if (isString(val.target.value)) {
                              formik.handleChange(val);
                            }
                          }}
                          onBlur={formik.handleBlur}
                          value={formik.values.description}
                          helperText={
                            formik.touched.description &&
                            formik.errors.description
                          }
                        />
                      </Grid>
                    </Grid>
                    <Grid item lg={6} md={4} sm={4} xs={12}>
                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Typography className="custom_label"> {translate?.main?.Title} {translate?.main?.Arabic}</Typography>
                        <TextField
                          hiddenLabel
                          type="text"
                          name="title_ar"
                          variant="outlined"
                          fullWidth
                          placeholder="Title (Arabic)"
                          id="title_ar"
                          className="text_field"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.title_ar}
                          helperText={
                            formik.touched.title_ar && formik.errors.title_ar
                          }
                        />
                      </Grid>
                      <div style={{ marginTop: 20 }} />

                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Typography className="custom_label">
                          {translate?.main?.Description} {translate?.main?.Arabic}
                        </Typography>
                        <TextField
                          className="text_field"
                          hiddenLabel
                          type="text"
                          placeholder="Description (Arabic)"
                          fullWidth
                          multiline
                          minRows={4}
                          maxRows={4}
                          name="description_ar"
                          variant="outlined"
                          id="description_ar"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.description_ar}
                          helperText={
                            formik.touched.description_ar &&
                            formik.errors.description_ar
                          }
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <div className="form_btn">
                    <Button
                      size="large"
                      type="submit"
                      className="btn btn_primary"
                    //   onClick={() => setError(true)}
                    >
                      {translate?.main?.Save}
                    </Button>
                  </div>
                </CardContent>
              </form>
            </Card>
          </div>
        </MainContainer>
      </>
    </div>
  );
};

export default AddTutorial;
