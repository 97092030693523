import { END_POINTS } from "../constants/url";
import { CommonBody } from "../types/General";

import emptySplitApi from "../utils/rtk";

type CommonResponseType = {
  statusCode: number;
  message: string;
};
type GetUserParams = {
  page: number;
  query: string;
};

type PutBlockUserParams = {
  userId: string;
  encryptedBody: CommonBody;
};

export const areaApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllAreas: builder.query<
      CommonResponseType & { data: any, cms: any },
      GetUserParams
    >({
      query: ({ page, query }) => ({
        url: END_POINTS.area + "?search=" + query + "&page=" + page,

        method: "GET",
      }),
    }),
    getSubAreas: builder.query<
      CommonResponseType & { data: any, cms: any },
      GetUserParams & { parentId: string | undefined }
    >({
      query: ({ page, query, parentId }) => ({
        url:
          END_POINTS.area +
          "/" +
          parentId +
          "?search=" +
          query +
          "&page=" +
          page,
        method: "GET",
      }),
    }),

    changeAreaStatus: builder.mutation<
      { message: string; statusCode: number },
      PutBlockUserParams
    >({
      query: ({ encryptedBody, userId }) => ({
        url: `${END_POINTS.area}/${userId}`,
        method: "PUT",
        body: encryptedBody,
      }),
    }),

    postAddArea: builder.mutation<
      { message: string; statusCode: number },
      CommonBody
    >({
      query: (body) => ({
        url: END_POINTS.area,
        method: "POST",
        body,
      }),
    }),

    getAreaById: builder.query<
      { message: string; statusCode: number; data: any },
      { areaId: string | undefined }
    >({
      query: ({ areaId }) => ({
        url: `${END_POINTS.getAreaById}/${areaId}`,
        method: "GET",
      }),
    }),

    updateArea: builder.mutation<
      { message: string; statusCode: number },
      PutBlockUserParams
    >({
      query: ({ encryptedBody, userId }) => ({
        url: `${END_POINTS.area}/${userId}`,
        method: "PUT",
        body: encryptedBody,
      }),
    }),

    deleteArea: builder.mutation<CommonResponseType, { areaId: string }>({
      query: ({ areaId }) => ({
        url: `${END_POINTS.area}/${areaId}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useLazyGetAllAreasQuery,
  useChangeAreaStatusMutation,
  usePostAddAreaMutation,
  useLazyGetAreaByIdQuery,
  useUpdateAreaMutation,
  useDeleteAreaMutation,
  useLazyGetSubAreasQuery,
} = areaApi;
