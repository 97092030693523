import React, { useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import {
  Button,
  Card,
  FormControl,
  IconButton,
  MenuItem,
  Select,
  SelectChangeEvent,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import Box from "@mui/material/Box";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import SearchBar from "../../components/SearchBar";
import { useNavigate } from "react-router-dom";
import LibraryAddIcon from "@mui/icons-material/LibraryAdd";
import WarnModal from "../../components/WarnModal";

import { isValidInput } from "../../utils/validations";
import { Loader, showError, showToast } from "../../constants";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import Pagination from "../../components/Pagination";
import useAuth from "../../hooks/useAuth";
import {
  useChangeAreaStatusMutation,
  useDeleteAreaMutation,
  useLazyGetAllAreasQuery,
} from "../../services/area";
import { CommonBody } from "../../types/General";
import useTranslation from "../../hooks/Translation";
import { usePostCmsMutation } from "../../services/cms";

const ManageAreas = () => {
  const navigate = useNavigate();
  const [getAreas] = useLazyGetAllAreasQuery();
  const [open, setOpen] = useState<boolean>(false);
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [deleteArea] = useDeleteAreaMutation();
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [totalCount, setTotalCount] = useState<number>(0);
  const [AllAreas, setAllAreas] = useState<any>([]);
  const [cmsId, setCmsId] = useState<string>("");
  const [sort, setSort] = useState<boolean>(false);
  const [sort2, setSort2] = useState<boolean>(false);
  const [postCms, { isLoading }] = usePostCmsMutation();
  const [page, setPage] = useState(1);
  const [selectedId, setSelectedId] = useState<string>("");
  const [changeAreaStatus] = useChangeAreaStatusMutation();
  const translate = useTranslation();
  const [hidePermission, setHidePermission] = useState<any>({
    isAdd: true,
    isDelete: true,
  });
  const [loading, setLoading] = useState(false);

  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };
  let totalPages = Math.ceil(totalCount / 10);

  const label = { inputProps: { "aria-label": "Switch demo" } };

  const getCategory = async () => {
    setLoading(true);
    try {
      const response = await getAreas({
        query: debouncedSearchTerm.trim(),
        page: page,
      }).unwrap();
      if (response?.statusCode === 200) {
        setLoading(false);
        setAllAreas(response?.data?.area || []);
        setTotalCount(response?.data?.count);
        setCmsId(response?.data?.cms?._id);
        setSort(response?.data?.cms?.governateAlphabetSort)
        setSort2(response?.data?.cms?.areaCountSort)
      } else {
        setAllAreas([]);
      }
    } catch (error: any) {
      setLoading(false);
      showError(error?.data?.message || "");
    }
  };

  const updateCms = async (val1: boolean | undefined, val2: boolean | undefined) => {
    const body = {
      governateAlphabetSort: val1,
      areaCountSort: val2,
    }
    // console.log(body, "body for update");
    try {
      let encryptedBody = generateEncryptedKeyBody(body) as CommonBody;
      const response = await postCms({
        encryptedBody,
        userId: cmsId,
      }).unwrap();
      if (response?.statusCode === 200) {
        getCategory();
      }

    } catch (error: any) {
      console.log(error, "errror");
      showError(error?.data?.message || "");
    }
  }

  // console.log(sort + "=sort" + "   " + sort2 + "=sort2");


  const updateUserStatus = async (id: string, status: boolean) => {
    const data = {
      isBlocked: status,
    };
    let encryptedBody = generateEncryptedKeyBody(data) as CommonBody;
    const response = await changeAreaStatus({
      encryptedBody,
      userId: id,
    }).unwrap();
    if (response?.statusCode === 200) {
      const updatedData = AllAreas.map((x: any) => {
        if (x._id === id) {
          return {
            ...x,
            isBlocked: status,
          };
        }
        return x;
      });
      setAllAreas(updatedData);
      showToast("Status updated successfully");
    } else {
      showError("Please try again");
    }
  };

  //   const checkPermission = () => {
  //     const permissions = userData?.permission;
  //     if (permissions?.length) {
  //       let idx = -1;
  //       idx = permissions?.findIndex(
  //         (ele: any) => ele?.name === "Manage Categories"
  //       );

  //       if (idx != -1) {
  //         let data = {
  //           isAdd: permissions[idx]?.isAdd,
  //           isDelete: permissions[idx]?.isDelete,
  //         };
  //         setHidePermission(data);
  //       }
  //     }
  //   };


  const handleDelete = async (id: string) => {
    try {
      const response = await deleteArea({ areaId: id }).unwrap();
      if (response?.statusCode === 200) {
        showToast("Governorate deleted successfully");
        getCategory();
      }
    } catch (error: any) {
      console.log(error, "errror");
      showError(error?.data?.message || "");
    }
  };


  console.log(sort, sort2, '======');




  useEffect(() => {
    getCategory();
  }, [debouncedSearchTerm, searchTerm]);

  //   useEffect(() => {
  //     if (userData && userData?.permission?.length) {
  //       checkPermission();
  //     }
  //   }, [userData]);



  return (
    <MainContainer>
      <Loader isLoad={loading} />
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">{translate?.main?.ManageAreas}</h1>
        </div>
        <Card className="cards">
          <Box className="custom_tabs">
            <Box className="cards_header">
              <div
                style={{
                  display: "flex",
                  gap: 10,
                  width: "70%",
                  alignItems: "center"
                }}
              >
                <SearchBar
                  value={searchTerm}
                  searchTerm={searchTerm}
                  onCross={() => setSearchTerm("")}
                  setDebouncedSearchTerm={setDebouncedSearchTerm}
                  onChange={(val: any) => {
                    if (isValidInput(val.target.value)) {
                      setSearchTerm(val.target.value);
                    }
                  }}
                />
                <h4>By alphabets :</h4>
                <Switch size="medium" {...label}
                  checked={sort}
                  onChange={(e) => {
                    setSort(e.target.checked);
                    if (sort2 && e.target.checked) {
                      setSort2(false);
                    }
                    updateCms(e.target.checked, e.target.checked ? false : sort2)
                  }}
                  name="sort"
                  id="sort"
                />
                <h4>By areas :</h4>
                <Switch size="medium" {...label}
                  checked={sort2}
                  onChange={(e) => {
                    setSort2(e.target.checked);
                    if (sort && e.target.checked) {
                      setSort(false);
                    }
                    updateCms(e.target.checked ? false : sort, e.target.checked)
                  }}
                  name="sort2"
                  id="sort2"
                />
                {/* <h4>By alphabets :</h4>
                <Switch
                  size="medium"
                  {...label}
                  checked={sort}
                  onChange={() => {
                    const newSort = !sort;
                    const newSort2 = newSort ? false : sort2;
                    updateCms(newSort, newSort2);
                  }}
                />
                <h4>By areas :</h4>
                <Switch
                  size="medium"
                  {...label}
                  checked={sort2}
                  onChange={() => {
                    const newSort2 = !sort2;
                    const newSort = newSort2 ? false : sort;
                    updateCms(newSort, newSort2);
                  }}
                /> */}
              </div>

              {hidePermission?.isAdd ? (
                <Box className="cards_header_right">
                  <Button
                    className="btn btn_primary"
                    onClick={() => navigate("/manage-areas/add")}
                  >
                    {translate?.main?.AddGovernorate}
                  </Button>
                </Box>
              ) : null}

            </Box>

            <TableContainer className="table_container">
              <Box className="heading"></Box>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">{translate?.main?.SNo}</TableCell>

                    <TableCell>{translate?.main?.Governorate}</TableCell>
                    <TableCell>{translate?.main?.Status}</TableCell>
                    <TableCell> {translate?.main?.Action}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {AllAreas?.length > 0 ? (
                    AllAreas?.map((row: any, i: any) => (
                      <TableRow key={row?._id}>
                        <TableCell align="center">
                          {(page - 1) * 10 + i + 1}
                        </TableCell>

                        <TableCell>{row?.name || "-"}</TableCell>
                        <TableCell>
                          <Switch
                            {...label}
                            size="small"
                            checked={!row?.isBlocked}
                            onChange={() =>
                              updateUserStatus(row?._id, !row?.isBlocked)
                            }
                          />
                        </TableCell>
                        <TableCell>
                          <Box className="table_actions">
                            <Tooltip title="Areas" placement="top">
                              <IconButton
                                onClick={() =>
                                  navigate(
                                    `/manage-areas/sub-areas/${row?._id}`
                                  )
                                }
                              >
                                <LibraryAddIcon />
                              </IconButton>
                            </Tooltip>
                            <IconButton
                              onClick={() =>
                                navigate(`/manage-areas/edit/${row?._id}`)
                              }
                            >
                              <ModeEditIcon />
                            </IconButton>

                            <IconButton
                              onClick={() => {
                                setOpen(true);
                                setSelectedId(row?._id);
                              }}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Box>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={9} align="center">
                        {translate?.main?.NoAreaFound}
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Card>
        {AllAreas?.length > 0 ? (
          <Pagination
            setPage={setPage}
            module={AllAreas}
            page={page}
            onPageChange={onPageChange}
            totalPages={totalPages}
          />
        ) : (
          ""
        )}
      </div>

      <WarnModal
        open={open}
        setOpen={setOpen}
        handleDelete={() => {
          handleDelete(selectedId);
        }}
        name="Governorate"
      />
    </MainContainer>
  );
};

export default ManageAreas;
