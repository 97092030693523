import {
  Box,
  Button,
  Card,
  CardContent,
  FormControl,
  Grid,
  Input,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";

import { ChangeEvent, useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import { useNavigate, useParams } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import {
  useLazyGetAreaQuery,
  useLazyGetParentAreaQuery,
  usePostAddPropertyMutation,
  useUpdatePropertyMutation,
  useLazyGetPropertyByIdQuery,
} from "../../services/property";
import * as Yup from "yup";
import { isString, useFormik } from "formik";
import { Loader, showError, showToast } from "../../constants";
import { useLazyGetCategoriesQuery } from "../../services/main";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import { CommonBody, Property } from "../../types/General";
import CloseIcon from "@mui/icons-material/Close";
import { isNumber } from "../../utils/validations";
import { UploadMedia, UploadVideo } from "../../utils/mediaUpload";
import useTranslation from "../../hooks/Translation";

const AddProperty = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [getAreaQuery] = useLazyGetAreaQuery();
  const [getParentArea] = useLazyGetParentAreaQuery();
  const [getCategories] = useLazyGetCategoriesQuery();
  const [addProperty] = usePostAddPropertyMutation();
  const [updateProperty] = useUpdatePropertyMutation();
  const [getPropertyDetails] = useLazyGetPropertyByIdQuery();
  // const [subCategory, setSubCategory] = useState<any>([]);
  const [allArea, setAllArea] = useState<any>([]);
  const [selectedArea, setSelectedArea] = useState("");

  const [subArea, setSubArea] = useState<any>([]);
  const [selectedSubArea, setSelectedSubArea] = useState("");

  const [category, setCategory] = useState<any>([]);
  const [subCategory, setSubCategory] = useState<any>([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [myImages, setMyImages] = useState<any>([]);
  console.log(myImages);
  const [error, setError] = useState(false);
  const [selectedSubCategory, setSelectedSubCategory] = useState("");
  const [image, setImage] = useState("");
  const [propertyData, setPropertyData] = useState<Property>();
  const [listing, setListing] = useState<any>("");
  const [loading, setLoading] = useState(false);
  const translate = useTranslation();
  const getGovernorate = async () => {
    try {
      const response = await getAreaQuery({
        page: 1,
      }).unwrap();
      if (response?.statusCode === 200) {
        setAllArea(response?.data || []);
      } else {
        setAllArea([]);
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  };
  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
      const allowedTypes = [
        "image/png",
        "image/jpeg",
        "image/jpg",
        "image/svg+xml",
        "video/mp4",
        "video/webm",
        "video/ogg",
      ];
      const invalidFiles = Array.from(files).filter(
        (file) => !allowedTypes.includes(file.type)
      );
      if (invalidFiles.length > 0) {
        showError("Invalid file type. Please upload only images or videos.");
        return;
      }
      if (myImages.length + files.length > 7) {
        showError("Cannot upload more than 7 images or videos");
        return;
      }
      const uploadedMedia = Array.from(files).map((file) => ({
        file,
        url: URL.createObjectURL(file),
      }));
      setMyImages((prevMedia: any) => [...prevMedia, ...uploadedMedia]);
    }
  };

  const handleCrossClick = async (e: any, i: any) => {
    e.stopPropagation();
    if (myImages.includes(i)) {
      let arr = [];
      arr = myImages.filter((name: any) => name !== i);
      setMyImages(arr);
    }
  };

  const getArea = async () => {
    try {
      const response = await getParentArea({
        page: 1,
        areaId: selectedArea,
        size: 50,
      }).unwrap();
      if (response?.statusCode === 200) {
        setSubArea(response?.data || []);
      } else {
        setSubArea([]);
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  };

  const getCategory = async () => {
    try {
      const response = await getCategories({
        query: "",
        page: 1,
        size: 50,
        type: 1,
        categoryId: "",
      }).unwrap();
      if (response?.statusCode === 200) {
        setCategory(response?.data?.category || []);
      } else {
        setCategory([]);
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  };

  const getSubCategory = async () => {
    try {
      const response = await getCategories({
        query: "",
        page: 1,
        size: 50,
        type: 2,
        categoryId: selectedCategory,
      }).unwrap();
      if (response?.statusCode === 200) {
        setSubCategory(response?.data?.category || []);
      } else {
        setSubCategory([]);
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  };

  const getUserDetailById = async (id: string | undefined) => {
    try {
      const response = await getPropertyDetails({ propertyId: id }).unwrap();
      if (response?.statusCode === 200) {
        setPropertyData(response?.data);
        setSelectedCategory(response?.data?.categoryId?._id);
        setSelectedSubCategory(response?.data?.subCategoryId?._id);
        setSelectedArea(response?.data?.areaId?._id);
        setSelectedSubArea(response?.data?.subAreaId?._id);
        setListing(response?.data?.listingType);
      }
    } catch (error: any) {
      console.log(error, "errror");
      showError(error?.data?.message || "");
    }
  };
  console.log(propertyData, "propertyData");


  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      price: propertyData?.price || "",
      description: propertyData?.description || "",
      totalArea: propertyData?.totalArea || "",
      mapLink:propertyData?.mapLink ||"",
    },
    validationSchema: Yup.object({
      price: Yup.string()
        .required("This field is required")
        .max(20, "Maximum 20 character are allowed")
        .min(2, "Minimum 2 characters are required"),
      description: Yup.string()
        .required("This field is required")
        .max(450, "Maximum 450 character are allowed")
        .min(2, "Minimum 2 characters are required"),
      totalArea: Yup.string()
        .required("This field is required")
        .max(20, "Maximum 20 character are allowed"),
      mapLink: Yup.string()
        .max(100, "Maximum 100 character are allowed"),
    }),
    onSubmit: async () => {
      formik.setSubmitting(true);
      setLoading(true);
      let images: string[] = [];
      if (myImages?.length) {
        setLoading(true);
        await Promise.all(
          myImages?.map(async (item: any) => {
            const res = await UploadVideo(item?.file);
            if (res?.statusCode === 200 && res?.data?.length) {
              images?.push(res?.data);
            }
          })
        );
      }
      setLoading(false);

      const data = {
        image: images,
        areaId: selectedArea,
        subAreaId: selectedSubArea,
        categoryId: selectedCategory,
        subCategoryId: selectedSubCategory,
        description: formik?.values?.description,
        dontDisturb: true,
        price: formik?.values?.price,
        negotiablePrice: true,
        totalArea: formik?.values?.totalArea,
        listingType: Number(listing),
        mapLink: formik.values.mapLink,
      };

      console.log(data);

      if (id && id !== "add") {
        try {
          let encryptedBody = generateEncryptedKeyBody(data) as CommonBody;
          const response = await updateProperty({
            propertyId: id,
            encryptedBody,
          }).unwrap();
          if (response?.statusCode === 200) {
            showToast("Property updated successfully");
            navigate("/manage-properties");
          } else {
            showError(response?.message || "");
          }
        } catch (error: any) {
          showError(error?.data?.message || "");
          console.log(error);
        }
      } else {
        try {
          let encryptedBody = generateEncryptedKeyBody(data) as CommonBody;
          const response = await addProperty(encryptedBody).unwrap();
          if (response?.statusCode === 200) {
            showToast("Property Added Successfully");
            formik.resetForm();
            navigate("/manage-properties");
          } else {
            showError(response?.message || "");
          }
        } catch (error: any) {
          showError(error?.data?.message || "");
          console.log(error);
        }
      }
    },
  });

  // const handleArea = (event: SelectChangeEvent) => {
  //   setArea(event.target.value as string);
  // };

  const handleSubcategory = (event: SelectChangeEvent) => {
    setSelectedSubCategory(event.target.value as string);
  };

  const handleArea = (event: SelectChangeEvent) => {
    setSelectedArea(event.target.value as string);
  };

  const handleSubArea = (event: SelectChangeEvent) => {
    setSelectedSubArea(event.target.value as string);
  };
  const handleCategory = (event: SelectChangeEvent) => {
    setSelectedCategory(event.target.value as string);
  };

  const handleRatingChange = (event: SelectChangeEvent) => {
    setListing(event.target.value as string);
  };

  useEffect(() => {
    getGovernorate();
  }, []);

  useEffect(() => {
    getArea();
  }, [selectedArea]);

  useEffect(() => {
    getCategory();
  }, []);

  useEffect(() => {
    getSubCategory();
  }, [selectedCategory]);

  useEffect(() => {
    if (id && id !== "add") {
      getUserDetailById(id);
    }
  }, []);

  useEffect(() => {
    if (propertyData && propertyData?.image) {
      const documentImages = propertyData?.image.map((documentUrl) => ({
        url: documentUrl,
      }));
      setMyImages((prevImages: any) => [...prevImages, ...documentImages]);
    }
  }, [propertyData]);

  const formatNumberWithCommas = (number: any) => {
    if (number > 0) {
      const formattedNumber = number.toLocaleString("en-IN");
      return `${formattedNumber}`;
    }
    return "";
  };


  const handlePriceChange = (event: any) => {
    const inputValue = event.target.value;
    // Remove non-numeric characters and format as a number
    const newValue = parseFloat(inputValue.replace(/[^\d.]/g, ""));
    // Check if the numericValue is NaN or a valid number
    if (!isNaN(newValue)) {
      formik.setFieldValue("price", newValue);
    } else {
      formik.setFieldValue("price", "");
    }
  };

  useEffect(() => {
    if (propertyData) {
      if (propertyData?.image?.length) {
        if (propertyData?.image?.length && myImages?.length === 0) {
          let newObj = propertyData?.image?.map((item: any) => {
            return {
              type: item.endsWith(".mp4") ? "video" : "image",
              file: "File" as any,
              url: item as string,
            };
          });
          setMyImages([...myImages, ...newObj]);
        }
      }
    }
  }, [propertyData]);

  return (
    <MainContainer>
      <Loader isLoad={loading} />
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">
            {" "}
            {translate?.main?.Add} {translate?.main?.Property}
          </h1>
          <Button
            className="btn btn_primary"
            onClick={() => {
              navigate("/manage-properties");
            }}
          >
            {translate?.main?.Back}
          </Button>
        </div>
        <Card className="cards">
          <form onSubmit={formik.handleSubmit}>
            <CardContent sx={{ p: 1 }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Grid item xs={12} className="heading">
                    <Typography className="custom_label" variant="h5">
                      {translate?.main?.Upload} {translate?.main?.Image}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={2} className="form_control">
                      <Grid
                        item
                        lg={4}
                        md={4}
                        sm={6}
                        xs={12}
                        className="upload_document v2 w_50"
                      >
                        <label htmlFor="icon-button-file-certi">
                          <div className="inner">
                            <AddIcon />
                            <Input
                              id="icon-button-file-certi"
                              type="file"
                              inputProps={{
                                accept: ".png, .jpg, .jpeg, .svg,.mp4",
                                multiple: true,
                              }}
                              onChange={handleFileUpload}
                            />
                          </div>
                        </label>
                        {/* {error && !myImages?.length ? (
                          <h6 className="err_msg">This field is required.</h6>
                        ) : (
                          ""
                        )} */}
                      </Grid>

                      {myImages?.length ? (
                        <>
                          {myImages?.map((item: any, i: any) => {
                            return (
                              <Grid
                                item
                                lg={4}
                                md={4}
                                sm={6}
                                xs={12}
                                className="upload_document w_50"
                                key={i}
                              >
                                <div className="inner">
                                  <div className="cross_icn">
                                    <CloseIcon
                                      onClick={(e) => handleCrossClick(e, item)}
                                    />
                                  </div>

                                  {item.type === "image" ? (
                                    <figure>
                                      <img src={item.url} alt="upload" />
                                    </figure>
                                  ) : item.type === "video" ? (
                                    <video width="100%" height="100%" controls>
                                      <source src={item.url} type="video/mp4" />
                                      {
                                        translate?.main
                                          ?.Yourbrowserdoesnotsupportthevideotag
                                      }
                                    </video>
                                  ) : (
                                    <div>
                                      {item.file.type.startsWith("image/") ? (
                                        <img
                                          src={item.url}
                                          style={{ width: 310, height: 190 }}
                                          alt="upload"
                                        />
                                      ) : item.file.type.startsWith(
                                        "video/"
                                      ) ? (
                                        <video
                                          style={{ width: 310, height: 190 }}
                                          controls
                                        >
                                          <source
                                            src={item.url}
                                            type={item.file.type}
                                          />
                                          {
                                            translate?.main
                                              ?.Yourbrowserdoesnotsupportthevideotag
                                          }
                                        </video>
                                      ) : null}
                                    </div>
                                  )}
                                </div>
                              </Grid>
                            );
                          })}
                        </>
                      ) : null}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Typography className="custom_label">
                    {translate?.dashboard?.Select}{" "}
                    {translate?.main?.Governorate}
                  </Typography>
                  <FormControl sx={{ width: "100%" }}>
                    <Select
                      className="select_div"
                      fullWidth
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={selectedArea}
                      inputProps={{
                        "aria-label": "Without label",
                      }}
                      displayEmpty
                      onChange={handleArea}
                    >
                      <MenuItem value="" disabled>
                        {translate?.dashboard?.Select}{" "}
                        {translate?.main?.Governorate}
                      </MenuItem>
                      {allArea?.area?.length
                        ? allArea?.area?.map((item: any) => (
                          <MenuItem key={item?._id} value={item?._id}>
                            {item?.name || ""}
                          </MenuItem>
                        ))
                        : null}
                    </Select>
                    {!selectedArea && error ? (
                      <h6 className="err_msg">
                        {" "}
                        {translate?.main?.Please} {translate?.dashboard?.Select}{" "}
                        {translate?.main?.Governorate}{" "}
                      </h6>
                    ) : (
                      ""
                    )}
                  </FormControl>
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Typography className="custom_label">
                    {translate?.dashboard?.Select} {translate?.main?.Area}{" "}
                  </Typography>
                  <FormControl sx={{ width: "100%" }}>
                    <Select
                      className="select_div"
                      fullWidth
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={selectedSubArea}
                      disabled={selectedArea?.length == 0}
                      inputProps={{
                        "aria-label": "Without label",
                      }}
                      displayEmpty
                      onChange={handleSubArea}
                    >
                      <MenuItem value="" disabled>
                        {translate?.dashboard?.Select} {translate?.main?.Area}
                      </MenuItem>
                      {subArea?.area?.length
                        ? subArea?.area?.map((item: any) => (
                          <MenuItem key={item?._id} value={item?._id}>
                            {item?.name || ""}
                          </MenuItem>
                        ))
                        : null}
                    </Select>
                    {!selectedSubArea && error ? (
                      <h6 className="err_msg">
                        {translate?.main?.Please} {translate?.dashboard?.Select}{" "}
                        {translate?.main?.Area}{" "}
                      </h6>
                    ) : (
                      ""
                    )}
                  </FormControl>
                </Grid>

                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Typography className="custom_label">
                    {translate?.dashboard?.Select} {translate?.main?.Category}
                  </Typography>
                  <FormControl sx={{ width: "100%" }}>
                    <Select
                      className="select_div"
                      fullWidth
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={selectedCategory}
                      inputProps={{
                        "aria-label": "Without label",
                      }}
                      displayEmpty
                      onChange={handleCategory}
                    >
                      <MenuItem value="" disabled>
                        {translate?.dashboard?.Select}{" "}
                        {translate?.main?.Category}
                      </MenuItem>
                      {category?.length
                        ? category?.map((item: any) => (
                          <MenuItem key={item?._id} value={item?._id}>
                            {item?.name || ""}
                          </MenuItem>
                        ))
                        : null}
                    </Select>
                    {error && !selectedCategory ? (
                      <h6 className="err_msg">
                        {" "}
                        {translate?.main?.Please} {translate?.dashboard?.Select}{" "}
                        {translate?.main?.Category}{" "}
                      </h6>
                    ) : (
                      ""
                    )}
                  </FormControl>
                </Grid>

                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Typography className="custom_label">
                    {translate?.dashboard?.Select}{" "}
                    {translate?.main?.SubCategory}
                  </Typography>
                  <FormControl sx={{ width: "100%" }}>
                    <Select
                      className="select_div"
                      fullWidth
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={selectedSubCategory}
                      inputProps={{
                        "aria-label": "Without label",
                      }}
                      displayEmpty
                      onChange={handleSubcategory}
                      disabled={selectedCategory?.length == 0}
                    >
                      <MenuItem value="" disabled>
                        {translate?.dashboard?.Select}{" "}
                        {translate?.main?.Category}
                      </MenuItem>
                      {subCategory?.length
                        ? subCategory?.map((item: any) => (
                          <MenuItem key={item?._id} value={item?._id}>
                            {item?.name || ""}
                          </MenuItem>
                        ))
                        : null}
                    </Select>
                    {error && !selectedSubCategory ? (
                      <h6 className="err_msg">
                        {translate?.main?.Please} {translate?.dashboard?.Select}{" "}
                        {translate?.main?.Governorate}{" "}
                      </h6>
                    ) : (
                      ""
                    )}
                  </FormControl>
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Typography className="custom_label">
                    {" "}
                    {translate?.dashboard?.Select} {translate?.main?.Type}
                  </Typography>
                  <FormControl sx={{ width: "100%" }}>
                    <Select
                      className="select_div"
                      fullWidth
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={listing}
                      inputProps={{
                        "aria-label": "Without label",
                      }}
                      displayEmpty
                      onChange={handleRatingChange}
                    >
                      <MenuItem value="" disabled>
                        {translate?.dashboard?.Select} {translate?.main?.Type}
                      </MenuItem>
                      <MenuItem value="1">
                        {translate?.main?.For} {translate?.main?.Normal}{" "}
                      </MenuItem>
                      <MenuItem value="2">
                        {translate?.main?.For} {translate?.main?.Hightlighted}{" "}
                      </MenuItem>
                      <MenuItem value="3">
                        {translate?.main?.For} {translate?.main?.Agent}
                      </MenuItem>
                    </Select>
                    {error && !listing ? (
                      <h6 className="err_msg">
                        {translate?.main?.Please} {translate?.dashboard?.Select}{" "}
                        {translate?.main?.Governorate}
                      </h6>
                    ) : (
                      ""
                    )}
                  </FormControl>
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Typography className="custom_label">
                    {translate?.main?.Price}{" "}
                  </Typography>
                  <TextField
                    hiddenLabel
                    type={"text"}
                    name="price"
                    variant="outlined"
                    fullWidth
                    className="text_field"
                    placeholder={translate?.main?.Price}
                    inputProps={{ maxLength: 9 }}
                    onBlur={formik.handleBlur}
                    value={formatNumberWithCommas(formik.values.price)}
                    onChange={handlePriceChange}
                    helperText={formik.touched.price && formik.errors.price}
                  ></TextField>
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Typography className="custom_label">
                    {translate?.main?.Total} {translate?.main?.Area}{" "}
                  </Typography>
                  <TextField
                    hiddenLabel
                    type={"text"}
                    name="totalArea"
                    variant="outlined"
                    className="text_field"
                    fullWidth
                    placeholder={translate?.main?.Totalareainmetersquare}
                    onBlur={formik.handleBlur}
                    value={formik.values.totalArea}
                    onChange={(val) => {
                      const inputValue = val.target.value;
                      if (isNumber(inputValue) && inputValue.length <= 12) {
                        formik.handleChange(val);
                      }
                    }}
                    helperText={
                      formik.touched.totalArea && formik.errors.totalArea
                    }
                  ></TextField>
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Typography className="custom_label">
                    {" "}
                    {translate?.main?.Map} {translate?.main?.Link}
                  </Typography>
                  <TextField
                    hiddenLabel
                    type={"text"}
                    name="mapLink"
                    variant="outlined"
                    className="text_field"
                    fullWidth
                    placeholder={translate?.main?.Link}
                    onBlur={formik.handleBlur}
                    value={formik.values.mapLink}
                    onChange={(val) => {
                      const inputValue = val.target.value;
                      if (inputValue.length <= 100) {
                        formik.handleChange(val);
                      }
                    }}
                    helperText={formik.touched.mapLink && formik.errors.mapLink}
                  ></TextField>
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Typography className="custom_label">
                    {" "}
                    {translate?.main?.Description}
                  </Typography>
                  <TextField
                    hiddenLabel
                    type={"text"}
                    className="text_field"
                    name="description"
                    fullWidth
                    multiline
                    placeholder={translate?.main?.Description}
                    minRows={4}
                    onBlur={formik.handleBlur}
                    value={formik.values.description}
                    onChange={(val) => {
                      if (
                        val.target.value === " " ||
                        val.target.value === "."
                      ) {
                      } else if (isString(val.target.value)) {
                        formik.handleChange(val);
                      }
                    }}
                    helperText={
                      formik.touched.description && formik.errors.description
                    }
                  />
                </Grid>

                {/* <Grid item xs={12}>
                  <FormControlLabel
                    control={<Checkbox />}
                    label="Do not disturb unless if there is a serious buyer"
                  />
                </Grid> */}
                {/* <Grid item xs={12}>
                  <FormControlLabel
                    control={<Checkbox />}
                    label="The price is negotiable"
                  />
                </Grid> */}
              </Grid>
              <div className="form_btn">
                <Button
                  size="large"
                  type="submit"
                  className="btn btn_primary"
                  onClick={() => setError(true)}
                >
                  {translate?.main?.Save}
                </Button>
              </div>
            </CardContent>
          </form>
        </Card>
      </div>
    </MainContainer>
  );
};

export default AddProperty;
