import React, { useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import {
  Box,
  Button,
  Card,
  CardContent,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Select,
  TextField,
  SelectChangeEvent,
  Typography,
  Switch,
} from "@mui/material";
import * as Yup from "yup";
import { isString, useFormik } from "formik";
import {
  useAddSubscriptionMutation,
  useUpdateSubscriptionMutation,
  useLazyGetSubscriptionByIdQuery,
} from "../../services/subscription";
import DeleteIcon from "@mui/icons-material/Delete";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import { CommonBody, Subscription } from "../../types/General";
import { Loader, showError, showToast } from "../../constants";
import { isNumber } from "../../utils/validations";
import useTranslation from "../../hooks/Translation";

const AddSubscriptionPlan = () => {
  const { id } = useParams();
  const location = useLocation();
  const { state } = location;
  const [addSubscription] = useAddSubscriptionMutation();
  const [updateSubscription] = useUpdateSubscriptionMutation();
  const [subscriptionDetails] = useLazyGetSubscriptionByIdQuery();
  const navigate = useNavigate();
  const [subsById, setSubsById] = useState<Subscription>();
  const [selectedFeature, setSelectedFeature] = useState<any>();
  const [receiver, setReceiver] = useState<string>("");
  const [receiverAr, setReceiverAr] = useState<string>("");
  const [error, setError] = useState(false);
  const timeDurations:any = {
    1: "شهر",
    2: "شهرين",
    3: "٣ أشهر",
    4: "٤ أشهر",
    5: "٥ أشهر",
    6: "٦ أشهر",
  };
  const timeDurations2:any = {
    "شهر":"1" ,
    "شهرين":"2",
    "٣ أشهر":"3",
    "٤ أشهر":"4",
    "٥ أشهر" :"5" ,
    "٦ أشهر":"6",
  };
  const handleReceiverChange = (event: SelectChangeEvent) => {
    setReceiver(event.target.value as string);
    setReceiverAr(timeDurations[event.target.value]);

  };

  
  const handleReceiverChangeAr = (event: SelectChangeEvent) => {
    setReceiverAr(event.target.value as string);
    setReceiver(timeDurations2[event.target.value]);

  };

  const [featureEnabled, setFeatureEnabled] = useState(false); // Set to true or false based on your logic
  const [loading, setLoading] = useState(false);

  const handleSwitchChange = () => {
    setFeatureEnabled(!featureEnabled);
    // Add any other logic you want to execute when the switch changes state
  };

  const [switchValue, setSwitchValue] = useState(1);
  console.log(switchValue);

  const handleSwitchClick = () => {
    setSwitchValue((prevValue) => (prevValue === 1 ? 2 : 1));
  };

  const handleSwitchToggle = (id: string) => {
    setSelectedFeature(id);
    // Implement your logic to send the feature id in the body
    console.log("Feature ID:", id);
  };
  const translate = useTranslation();
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      price: subsById?.price || "",
      description: subsById?.description || "",
      planName: subsById?.planName || "",
      description_ar: subsById?.description_ar || "",
      planName_ar: subsById?.planName_ar || "",
      normalListing: subsById?.normalCredit || "",
      highlightedListing: subsById?.highlightCredit || "",
      acceptRequest: subsById?.acceptRequests || "",

    },
    validationSchema: Yup.object({
      price: Yup.string()
        .required("This field is required")
        .max(20, "Maximum 20 character are allowed"),

      normalListing: Yup.string()
        .required("This field is required")
        .max(20, "Maximum 20 character are allowed"),

      highlightedListing: Yup.string()
        .required("This field is required")
        .max(20, "Maximum 20 character are allowed"),

      acceptRequest: Yup.string()
        .required("This field is required")
        .max(20, "Maximum 20 character are allowed"),

      description: Yup.string()
        .required("This field is required")
        .max(450, "Maximum 450 character are allowed")
        .min(2, "Minimum 2 characters are required"),
      description_ar: Yup.string()
        .required("This field is required")
        .max(450, "Maximum 450 character are allowed")
        .min(2, "Minimum 2 characters are required"),
      planName: Yup.string()
        .required("This field is required")
        .max(20, "Maximum 20 character are allowed")
        .min(2, "Minimum 2 characters are required"),
      planName_ar: Yup.string()
        .required("This field is required")
        .max(20, "Maximum 20 character are allowed")
        .min(2, "Minimum 2 characters are required"),
    }),
    onSubmit: async () => {
      formik.setSubmitting(true);

      const data = {
        planName: formik?.values?.planName,
        description: formik?.values?.description,
        planName_ar: formik?.values?.planName_ar,
        description_ar: formik?.values?.description_ar,
        validity: Number(receiver),
        validity_ar:receiverAr,
        feature: featureEnabled,
        price: formik?.values?.price,
        normalCredit: Number(formik?.values?.normalListing),
        highlightCredit: Number(formik?.values?.highlightedListing),
        acceptRequests: Number(formik?.values?.acceptRequest),
      };

      console.log(data,"body for plan");

      if (id && id !== "add") {
        try {
          setLoading(true);
          let encryptedBody = generateEncryptedKeyBody(data) as CommonBody;
          const response = await updateSubscription({
            subsId: id,
            encryptedBody,
          }).unwrap();
          if (response?.statusCode === 200) {
            setLoading(false);
            showToast("Plan updated successfully");
            navigate("/manage-subscription");
          } else {
            setLoading(false);
            showError(response?.message || "");
          }
        } catch (error: any) {
          setLoading(false);
          showError(error?.data?.message || "");
          console.log(error);
        }
      } else {
        try {
          setLoading(true);
          let encryptedBody = generateEncryptedKeyBody(data) as CommonBody;
          const response = await addSubscription(encryptedBody).unwrap();
          if (response?.statusCode === 200) {
            setLoading(false);
            showToast("Plan Added Successfully");
            formik.resetForm();
            navigate("/manage-subscription");
          } else {
            setLoading(false);
            showError(response?.message || "");
          }
        } catch (error: any) {
          setLoading(false);
          showError(error?.data?.message || "");
          console.log(error);
        }
      }
    },
  });

  const formatNumberWithCommas = (number: any) => {
    if (number > 0) {
      const formattedNumber = number.toLocaleString("AED");
      return `${formattedNumber}`;
    }
    return "";
  };

  const handlePriceChange = (event: any) => {
    const inputValue = event.target.value;
    // Remove non-numeric characters and format as a number
    const newValue = parseFloat(inputValue.replace(/[^\d.]/g, ""));
    // Check if the numericValue is NaN or a valid number
    if (!isNaN(newValue)) {
      formik.setFieldValue("price", newValue);
    } else {
      formik.setFieldValue("price", "");
    }
  };

  const getCategoryDetail = async (id: string | undefined) => {
    try {
      const res :any= await subscriptionDetails({ subId: id }).unwrap();
      if (res?.statusCode === 200) {
        setSubsById(res?.data);
        // setImage(res?.data?.image);
        // setSelectedCategory(res?.data?.parent?._id);
        setReceiver(res?.data?.validity);
        setReceiverAr(res?.data?.validity_ar)
        setFeatureEnabled(res?.data?.feature);
      }
    } catch (error: any) {
      console.log(error, "errror");
      showError(error?.data?.message || "");
    }
  };

  const label = { inputProps: { "aria-label": "Switch demo" } };
  const planFeatures = [
    {
      id: 1,
      name: "Show request to agents",
      enable: true,
    },
  ];

  useEffect(() => {
    if (id && id !== "add") {
      getCategoryDetail(id);
    }
  }, []);

  return (
    <MainContainer>
      <Loader isLoad={loading} />
      <div className="main_loyout">
        <div className="dashboard">
          {state?.type === "edit" ? (
            <h1 className="mn_hdng">{translate?.main?.EditSubscriptionPlan}</h1>
          ) : (
            <h1 className="mn_hdng">{translate?.main?.AddSubscriptionPlan}</h1>
          )}
          <Button
            className="btn btn_primary"
            onClick={() => {
              navigate("/manage-subscription");
            }}
          >
            {translate?.main?.Back}
          </Button>
        </div>
        <Card className="cards">
          <form onSubmit={formik.handleSubmit}>
            <CardContent sx={{ p: 1 }}>
              <Grid container spacing={2}>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Typography className="custom_label">
                    {translate?.main?.PlanName} ({translate?.main?.English})
                  </Typography>
                  <TextField
                    hiddenLabel
                    type={"text"}
                    name="planName"
                    variant="outlined"
                    fullWidth
                    className="text_field"
                    placeholder="Plan Name ( English )"
                    onBlur={formik.handleBlur}
                    value={formik.values.planName}
                    onChange={(val) => {
                      if (
                        val.target.value === " " ||
                        val.target.value === "."
                      ) {
                      } else if (isString(val.target.value)) {
                        formik.handleChange(val);
                      }
                    }}
                    helperText={
                      formik.touched.planName && formik.errors.planName
                    }
                  />
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Typography className="custom_label">
                    {translate?.main?.PlanName} ({translate?.main?.Arabic})
                  </Typography>
                  <TextField
                    hiddenLabel
                    type={"text"}
                    name="planName_ar"
                    variant="outlined"
                    fullWidth
                    className="text_field"
                    placeholder="Plan Name ( Arabic )"
                    onBlur={formik.handleBlur}
                    value={formik.values.planName_ar}
                    onChange={formik.handleChange}
                    helperText={
                      formik.touched.planName_ar && formik.errors.planName_ar
                    }
                  />
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Typography className="custom_label">
                    {translate?.main?.PlanValidity} ({translate?.main?.English})
                  </Typography>
                  <FormControl sx={{ width: "100%" }}>
                    <Select
                      fullWidth
                      className="select_div"
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={receiver}
                      // multiple
                      inputProps={{
                        "aria-label": "Without label",
                      }}
                      displayEmpty
                      onChange={handleReceiverChange}
                    >
                      <MenuItem value="" disabled>
                        {translate?.dashboard?.Select}
                      </MenuItem>
                      <MenuItem value="1">1 {translate?.main?.Month}</MenuItem>
                      <MenuItem value="2">2 {translate?.main?.Months}</MenuItem>
                      <MenuItem value="3">3 {translate?.main?.Months}</MenuItem>
                      <MenuItem value="4">4 {translate?.main?.Months}</MenuItem>
                      <MenuItem value="5">5 {translate?.main?.Months}</MenuItem>
                      <MenuItem value="6">6 {translate?.main?.Months}</MenuItem>
                    </Select>
                    {error && !receiver ? (
                      <h6 className="err_msg">
                        {translate?.main?.PleaseselectValidity}
                      </h6>
                    ) : (
                      ""
                    )}
                  </FormControl>
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Typography className="custom_label">
                    {translate?.main?.PlanValidity} ({translate?.main?.Arabic})
                  </Typography>
                  <FormControl sx={{ width: "100%" }}>
                    <Select
                      fullWidth
                      className="select_div"
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={receiverAr}
                      // multiple
                      inputProps={{
                        "aria-label": "Without label",
                      }}
                      displayEmpty
                      onChange={handleReceiverChangeAr}
                    >
                      <MenuItem value="" disabled>
                        {translate?.dashboard?.Select}
                      </MenuItem>
                      <MenuItem value="شهر">شهر</MenuItem>
                      <MenuItem value="شهرين">شهرين</MenuItem>
                      <MenuItem value="٣ أشهر">٣ أشهر</MenuItem>
                      <MenuItem value="٤ أشهر">٤ أشهر</MenuItem>
                      <MenuItem value="٥ أشهر">٥ أشهر</MenuItem>
                      <MenuItem value="٦ أشهر">٦ أشهر</MenuItem>
                    </Select>
                    {error && !receiverAr ? (
                      <h6 className="err_msg">
                        {translate?.main?.PleaseselectValidity}
                      </h6>
                    ) : (
                      ""
                    )}
                  </FormControl>
                </Grid>
                

                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Typography className="custom_label">
                    {translate?.main?.Description} {translate?.main?.English}
                  </Typography>
                  <TextField
                    hiddenLabel
                    type={"text"}
                    className="text_field"
                    name="description"
                    fullWidth
                    multiline
                    placeholder="Description ( English )"
                    minRows={4}
                    maxRows={6}
                    onBlur={formik.handleBlur}
                    inputProps={
                      {
                        maxLength:450
                      }
                      }
                    value={formik.values.description}
                    onChange={(val) => {
                      if (
                        val.target.value === " " ||
                        val.target.value === "."
                      ) {
                      } else if (isString(val.target.value)) {
                        formik.handleChange(val);
                      }
                    }}
                    helperText={
                      formik.touched.description && formik.errors.description
                    }
                  />
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Typography className="custom_label">
                    {translate?.main?.Description} {translate?.main?.Arabic}
                  </Typography>
                  <TextField
                  
                    hiddenLabel
                    type={"text"}
                    className="text_field"
                    name="description_ar"
                    fullWidth
                    multiline
                    placeholder="Description ( Arabic )"
                    minRows={4}
                    maxRows={6}
                    inputProps={
                      {
                        maxLength:450
                      }
                      }
                    onBlur={formik.handleBlur}
                    value={formik.values.description_ar}
                    onChange={formik.handleChange}
                    helperText={
                      formik.touched.description_ar &&
                      formik.errors.description_ar
                    }
                  />
                </Grid>

                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Typography className="custom_label">
                    {" "}
                    {translate?.main?.PlanPrice}{" "}
                  </Typography>
                  <TextField
                    hiddenLabel
                    type={"text"}
                    name="price"
                    variant="outlined"
                    fullWidth
                    className="text_field"
                    placeholder="Plan Price"
                    onBlur={formik.handleBlur}
                    value={formatNumberWithCommas(formik.values.price)}
                    // onChange={(val) => {
                    // if (
                    //   val.target.value === " " ||
                    //   val.target.value === "."
                    // ) {
                    // } else{
                    // }
                    // formik.handleChange(val);
                    // }}
                    onChange={handlePriceChange}
                    helperText={formik.touched.price && formik.errors.price}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Typography
                    component="h2"
                    sx={{
                      fontSize: "20px",

                      fontWeight: 700,
                    }}
                    // className="custom_label"
                  >
                    {translate?.main?.Feature}
                  </Typography>
                </Grid>

                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Typography className="custom_label">
                    {translate?.main?.NormalCredits}
                  </Typography>
                  <TextField
                    hiddenLabel
                    type={"text"}
                    name="normalListing"
                    variant="outlined"
                    fullWidth
                    className="text_field"
                    placeholder={translate?.main?.NormalCredits}
                    onBlur={formik.handleBlur}
                    value={formik.values.normalListing}
                    onChange={(val) => {
                      if (
                        val.target.value === " " ||
                        val.target.value === "."
                      ) {
                      } else if (isNumber(val.target.value)) {
                        formik.handleChange(val);
                      }
                    }}
                    helperText={
                      formik.touched.normalListing &&
                      formik.errors.normalListing
                    }
                  />
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Typography className="custom_label">
                    {translate?.main?.HighlightedCredits}
                  </Typography>
                  <TextField
                    hiddenLabel
                    type={"text"}
                    name="highlightedListing"
                    variant="outlined"
                    fullWidth
                    className="text_field"
                    placeholder="Highlighted Credits"
                    onBlur={formik.handleBlur}
                    value={formik.values.highlightedListing}
                    onChange={(val) => {
                      if (
                        val.target.value === " " ||
                        val.target.value === "."
                      ) {
                      } else if (isNumber(val.target.value)) {
                        formik.handleChange(val);
                      }
                    }}
                    helperText={
                      formik.touched.highlightedListing &&
                      formik.errors.highlightedListing
                    }
                  />
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Typography className="custom_label">
                    {translate?.main?.ManageRequest}
                  </Typography>
                  <TextField
                    hiddenLabel
                    type={"text"}
                    name="acceptRequest"
                    variant="outlined"
                    fullWidth
                    className="text_field"
                    placeholder="Accept Request"
                    onBlur={formik.handleBlur}
                    value={formik.values.acceptRequest}
                    onChange={(val) => {
                      if (
                        val.target.value === " " ||
                        val.target.value === "."
                      ) {
                      } else if (isNumber(val.target.value)) {
                        formik.handleChange(val);
                      }
                    }}
                    helperText={
                      formik.touched.acceptRequest &&
                      formik.errors.acceptRequest
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <Box
                    sx={{ display: "flex", gap: "10px", alignItems: "center" }}
                  >
                    <Typography
                      component="h2"
                      sx={{
                        fontSize: "20px",

                        fontWeight: 700,
                      }}
                      // className="custom_label"
                    >
                      {translate?.main?.Showrequesttoagents}
                    </Typography>

                    <Switch
                      {...label}
                      size="small"
                      checked={featureEnabled}
                      onChange={handleSwitchChange}
                    />
                  </Box>
                </Grid>
              </Grid>
              <div className="form_btn">
                <Button
                  size="large"
                  type="submit"
                  className="btn btn_primary"
                  onClick={() => setError(true)}
                >
                  {translate?.main?.Save}
                </Button>
              </div>
            </CardContent>
          </form>
        </Card>
      </div>
    </MainContainer>
  );
};

export default AddSubscriptionPlan;