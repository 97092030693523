import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import {
  Button,
  FormControl,
  Grid,
  MenuItem,
  NativeSelect,
  Paper,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import PeopleIcon from "@mui/icons-material/People";

import LineChart from "../../components/LineChart";
import PaidIcon from "@mui/icons-material/Paid";
import { useNavigate } from "react-router-dom";
import { Colors } from "../../assets";
import ListAltIcon from "@mui/icons-material/ListAlt";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

import { Loader, showError } from "../../constants";
import {
  useLazyGetDashboardCsvQuery,
  useLazyGetDashboardQuery,
  useLazyGetEarningGraphQuery,
  useLazyGetPropertiesSearchedByAreaQuery,
  useLazyGetUserGraphQuery,
} from "../../services/main";
import { Dashboard } from "../../types/General";
import useAuth from "../../hooks/useAuth";
import { Permissions } from "../../types/User";
import useTranslation from "../../hooks/Translation";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? Colors.primary : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const DashBoard = () => {
  const navigate = useNavigate();
  const userData = useAuth();
  const translate = useTranslation();
  const [searchProperty] = useLazyGetPropertiesSearchedByAreaQuery();
  const [getDashboardQuery] = useLazyGetDashboardQuery();
  const [searchData, setSearchData] = useState<any>();
  const [hidePermission, setHidePermission] = useState<
    Permissions | null | undefined
  >(null);
  const [dashboardCsv] = useLazyGetDashboardCsvQuery();
  const [loading, setLoading] = useState<boolean>(false);
  const [getUserGraphQuery] = useLazyGetUserGraphQuery();
  const [getEarningGraphQuery] = useLazyGetEarningGraphQuery();
  const [userFilter, setUserFilter] = useState<string>("monthly");
  const [earningFilter, setEarningFilter] = useState<string>("monthly");
  const [searchFilter, setSearchFilter] = useState<string>("monthly");
  const [dashboardData, setDashboardData] = useState<Dashboard>();
  const [userGraph, setUserGraph] = useState<any>([]);
  const [earningGraph, setEarningGraph] = useState<any>([]);

  const userPermissions = userData?.permission?.length
    ? userData?.permission
    : [];

  const handleOrderChange = (event: SelectChangeEvent) => {
    setUserFilter(event.target.value as string);
  };
  const handleSearchChange = (event: SelectChangeEvent) => {
    setSearchFilter(event.target.value as string);
  };

  const handleearningChange = (event: SelectChangeEvent) => {
    setEarningFilter(event.target.value as string);
  };

  function convertToInternationalCurrencySystem(labelValue: number) {
    // Nine Zeroes for Billions
    return Math.abs(Number(labelValue)) >= 1.0e9
      ? (Math.abs(Number(labelValue)) / 1.0e9).toFixed(2) + " B"
      : // Six Zeroes for Millions
      Math.abs(Number(labelValue)) >= 1.0e6
        ? (Math.abs(Number(labelValue)) / 1.0e6).toFixed(2) + " M"
        : // Three Zeroes for Thousands
        Math.abs(Number(labelValue)) >= 1.0e3
          ? (Math.abs(Number(labelValue)) / 1.0e3).toFixed(2) + " K"
          : Math.abs(Number(labelValue));
  }

  const getDashboard = async () => {
    setLoading(true);
    try {
      const response = await getDashboardQuery({}).unwrap();
      if (response?.statusCode === 200) {
        setLoading(false);
        setDashboardData(response?.data || "");
      } else {
        setDashboardData(undefined);
      }
    } catch (error: any) {
      setLoading(false);
      showError(error?.data?.message || "");
    }
  };
  const getPropertySearchedByArea = async () => {
    setLoading(true);
    try {
      const response = await searchProperty({ type: "yearly" }).unwrap();
      if (response?.statusCode === 200) {
        setLoading(false);
        setSearchData(response?.data?.userCount || "");
      } else {
        setSearchData(undefined);
      }
    } catch (error: any) {
      setLoading(false);
      showError(error?.data?.message || "");
    }
  };

  const getDashboardCsv = async () => {
    try {
      const response = await dashboardCsv({}).unwrap();
      if (response?.statusCode === 200) {
        window?.open(response?.data);
      } else {
        console.log("error");
      }
    } catch (error: any) {
      console.log(error?.data?.message || "");
    }
  };

  const getEarningGraph = async () => {
    try {
      const response = await getEarningGraphQuery({
        type: earningFilter,
      }).unwrap();
      if (response?.statusCode === 200) {
        setEarningGraph(response?.data?.earningCount);
      } else {
        setEarningGraph([]);
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  };

  const getUserGraph = async () => {
    try {
      const response = await getUserGraphQuery({
        type: userFilter,
      }).unwrap();
      if (response?.statusCode === 200) {
        setUserGraph(response?.data?.userCount);
      } else {
        setUserGraph([]);
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  };

  const names = userGraph?.map((item: any) => item._id);
  const values = userGraph?.map((item: any) => item.count);

  const rNames = earningGraph?.map((item: any) => item._id);
  const rValues = earningGraph?.map((item: any) => item.count);

  const graphOrderData = {
    labels: names,
    datasets: [
      {
        label: translate?.dashboard?.User,
        data: values,
        borderColor: Colors.primary,
        backgroundColor: Colors.primary,
      },
    ],
  };
  const graphRevenueData = {
    labels: rNames,
    datasets: [
      {
        label: translate?.dashboard?.Earning,
        data: rValues,
        borderColor: Colors.primary,
        backgroundColor: Colors.primary,
      },
    ],
  };

  const sNames = searchData?.map((item: any) => item?.area);
  const sData = searchData?.map((item: any) => item?.count);

  const PropertyData = {
    labels: sNames,
    datasets: [
      {
        label: translate?.dashboard?.Property,
        data: sData,
        borderColor: Colors.primary,
        backgroundColor: Colors.primary,
      },
    ],
  };

  useEffect(() => {
    getDashboard();
  }, []);

  useEffect(() => {
    getUserGraph();
  }, [userFilter]);

  useEffect(() => {
    getPropertySearchedByArea();
  }, [searchFilter]);

  useEffect(() => {
    getEarningGraph();
  }, [earningFilter]);

  const checkPermission = () => {
    const permission = userPermissions;

    if (permission?.length) {
      let idx = -1;
      idx = permission?.findIndex(
        (ele: Permissions) => ele?.name === "Dashboard"
      );

      if (idx > -1) {
        setHidePermission(permission[idx]);
      } else {
        navigate("/permission-denied", { replace: true });
      }
    }
  };

  useEffect(() => {
    if (userData && userPermissions?.length) {
      checkPermission();
    }
  }, [userData]);

  return (
    <div className="main_loyout">
      <Loader isLoad={loading} />

      <div className="dashboard">
        <h1 className="mn_hdng">{translate?.dashboard?.dashboard}</h1>
        <Button className="btn btn_primary" onClick={getDashboardCsv}>
          <FileDownloadIcon /> {translate?.dashboard?.ExportCsv}
        </Button>
      </div>

      <Grid container spacing={2} className="dashGrid">
        <Grid item lg={3} md={3} sm={6} xs={12}>
          <Item
            className="cards dashGrid_item"
            onClick={() => navigate("/manageusers")}
          >
            <PeopleIcon className="svg_icn" />
            <div>
              <h3>{translate?.dashboard?.totalUsers} </h3>
              <h4 className="mn_hdng">{dashboardData?.totalUser || "-"}</h4>
            </div>
          </Item>
        </Grid>

        <Grid item lg={3} md={3} sm={6} xs={12}>
          <Item
            className="cards dashGrid_item"
          // onClick={() => navigate("/manageservices")}
          >
            <ListAltIcon className="svg_icn" />
            <div>
              <h3>{translate?.dashboard?.totalProperty} </h3>
              <h4 className="mn_hdng">
                {dashboardData?.totalProperties || "-"}
              </h4>
            </div>
          </Item>
        </Grid>
        <Grid item lg={3} md={3} sm={6} xs={12}>
          <Item
            className="cards dashGrid_item"
          // onClick={() => navigate("/manageservices")}
          >
            <ListAltIcon className="svg_icn" />
            <div>
              <h3>{translate?.dashboard?.forSale} </h3>
              <h4 className="mn_hdng">
                {dashboardData?.propertiesForSale || "-"}
              </h4>
            </div>
          </Item>
        </Grid>
        <Grid item lg={3} md={3} sm={6} xs={12}>
          <Item
            className="cards dashGrid_item"
          // onClick={() => navigate("/manageservices")}
          >
            <ListAltIcon className="svg_icn" />
            <div>
              <h3>{translate?.dashboard?.forRent} </h3>
              <h4 className="mn_hdng">
                {dashboardData?.propertiesForRent || "-"}
              </h4>
            </div>
          </Item>
        </Grid>
        <Grid item lg={3} md={3} sm={6} xs={12}>
          <Item
            className="cards dashGrid_item"
          // onClick={() => navigate("/manageservices")}
          >
            <ListAltIcon className="svg_icn" />
            <div>
              <h3>{translate?.dashboard?.forExchange}</h3>
              <h4 className="mn_hdng">
                {dashboardData?.propertiesForExchange || "-"}
              </h4>
            </div>
          </Item>
        </Grid>
        <Grid item xs={3}>
          <Item
            className="cards dashGrid_item"
          // onClick={() => navigate("/manageservices")}
          >
            <PaidIcon className="svg_icn" />
            <div>
              <h3>{translate?.dashboard?.earnings}</h3>
              <h4 className="mn_hdng">
                {dashboardData?.totalEarning !== undefined
                  ? dashboardData?.totalEarning >= 1000000
                    ? `$ ${(dashboardData?.totalEarning / 1000000).toFixed(
                      1
                    )} m`
                    : convertToInternationalCurrencySystem(dashboardData?.totalEarning).toLocaleString() // Add toLocaleString() here
                  : "-"}
              </h4>
            </div>
          </Item>
        </Grid>
        <Grid item xs={3}>
          <Item
            className="cards dashGrid_item"
          // onClick={() => navigate("/manageservices")}
          >
            <PaidIcon className="svg_icn" />
            <div>
              <h3>{translate?.dashboard?.avgEarnings} </h3>
              <h4 className="mn_hdng">
                {dashboardData?.avgMonthlyEarning !== undefined
                  ? dashboardData?.avgMonthlyEarning >= 1000000
                    ? `$ ${(dashboardData?.avgMonthlyEarning / 1000000).toFixed(
                      2
                    )} m`
                    : Number(convertToInternationalCurrencySystem(dashboardData?.avgMonthlyEarning)).toFixed(2)
                  : "-"}
              </h4>
            </div>
          </Item>
        </Grid>
      </Grid>

      <Grid container spacing={2} className="dashGraph" sx={{ pt: 4 }}>
        <Grid item lg={6} md={6} sm={6} xs={12}>
          <Item className="cards dashGraph_item">
            <h2 className="mn_hdng">
              {translate?.dashboard?.totalUsers}
              <FormControl>
                <Select
                  className="select_div"
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={userFilter}
                  inputProps={{
                    "aria-label": "Without label",
                  }}
                  displayEmpty
                  onChange={handleOrderChange}
                >
                  <MenuItem value="" disabled>
                    {translate?.dashboard?.Select}
                  </MenuItem>
                  <MenuItem value="hourly">
                    {" "}
                    {translate?.dashboard?.Hourly}
                  </MenuItem>
                  <MenuItem value="weekly">
                    {" "}
                    {translate?.dashboard?.Weekly}
                  </MenuItem>
                  <MenuItem value="monthly">
                    {" "}
                    {translate?.dashboard?.Monthly}
                  </MenuItem>
                  <MenuItem value="yearly">
                    {" "}
                    {translate?.dashboard?.Yearly}
                  </MenuItem>
                </Select>
              </FormControl>
            </h2>
            <LineChart data={graphOrderData} />
          </Item>
        </Grid>
        <Grid item lg={6} md={6} sm={6} xs={12}>
          <Item className="cards dashGraph_item">
            <h2 className="mn_hdng">
              {translate?.dashboard?.earnings}
              <FormControl>
                <Select
                  className="select_div"
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={earningFilter}
                  inputProps={{
                    "aria-label": "Without label",
                  }}
                  displayEmpty
                  onChange={handleearningChange}
                >
                  <MenuItem value="" disabled>
                    {translate?.dashboard?.Select}
                  </MenuItem>
                  <MenuItem value="hourly">
                    {" "}
                    {translate?.dashboard?.Hourly}
                  </MenuItem>
                  <MenuItem value="weekly">
                    {" "}
                    {translate?.dashboard?.Weekly}
                  </MenuItem>
                  <MenuItem value="monthly">
                    {" "}
                    {translate?.dashboard?.Monthly}
                  </MenuItem>
                  <MenuItem value="yearly">
                    {" "}
                    {translate?.dashboard?.Yearly}
                  </MenuItem>
                </Select>
              </FormControl>
            </h2>
            <LineChart data={graphRevenueData} />
          </Item>
        </Grid>

        <Grid item lg={6} md={6} sm={6} xs={12}>
          <Item className="cards dashGraph_item">
            <h2 className="mn_hdng">
              {translate?.dashboard?.byArea}
              <FormControl>
                {/* <Select
                  className="select_div"
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={searchFilter}
                  inputProps={{
                    "aria-label": "Without label",
                  }}
                  displayEmpty
                  onChange={handleSearchChange}
                >
                  <MenuItem value="" disabled>
                    {translate?.dashboard?.Select}
                  </MenuItem>
                  <MenuItem value="hourly">
                    {" "}
                    {translate?.dashboard?.Hourly}
                  </MenuItem>
                  <MenuItem value="weekly">
                    {" "}
                    {translate?.dashboard?.Weekly}
                  </MenuItem>
                  <MenuItem value="monthly">
                    {" "}
                    {translate?.dashboard?.Monthly}
                  </MenuItem>
                  <MenuItem value="yearly">
                    {" "}
                    {translate?.dashboard?.Yearly}
                  </MenuItem>
                </Select> */}
              </FormControl>
            </h2>
            <LineChart data={PropertyData} />
          </Item>
        </Grid>
      </Grid>
    </div>
  );
};

export default DashBoard;
